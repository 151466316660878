import { AsyncPipe, DecimalPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { DougsDatePipe } from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';

@Component({
  selector: 'dougs-plan-confirmation-adjustment',
  templateUrl: './plan-confirmation-adjustment.component.html',
  styleUrls: ['./plan-confirmation-adjustment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, DecimalPipe, AsyncPipe, DougsDatePipe],
})
export class PlanConfirmationAdjustmentComponent {
  @Input() selectedPlan!: SubscriptionPlan;
  @Input() onMobile = false;

  constructor(public readonly companyStateService: CompanyStateService) {}
}
