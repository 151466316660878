@if (planModalService.plansOnActiveCompanyChange$ | async) {}
@if (planModalService.plans$ | async; as plans) {
  @switch (planModalService.step) {
    @case (0) {
      <dougs-plan-selection
        (choosePlan)="planModalService.choosePlan($event)"
        [plans]="plans"
        [disableClose]="planModalService.disableClose"
        [modalSize]="planModalService.getModalSize(plans, responsiveService.isMobile | async)"
      ></dougs-plan-selection>
    }
    @case (1) {
      <dougs-plan-confirmation
        (updateAccountingYearConfirmation)="planModalService.updateAccountingYearConfirmation($event)"
        [accountingYearConfirmation]="planModalService.accountingYearConfirmation"
        [selectedPlan]="planModalService.selectedPlan"
        [isConfirmingPlanSelection]="planModalService.isConfirmingPlanSelection"
        [modalRef]="planModalService.modalRef"
        [onMobile]="responsiveService.isMobile | async"
      ></dougs-plan-confirmation>
    }
  }

  @if (
    (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin ||
    planModalService.step === 0 ||
    !planModalService.disableClose ||
    planModalService.step === 1
  ) {
    <div dougsModalFooter>
      @if (planModalService.hasContactButton) {
        <dougs-button color="secondary" (click)="planModalService.contactSales($event)">
          Contacter un conseiller
        </dougs-button>
      }

      @if (
        ((userStateService.loggedInUser$ | async)?.isAccountantOrAdmin || !planModalService.disableClose) &&
        (responsiveService.isMobile | async) &&
        planModalService.step === 1
      ) {
        <div>
          <a
            dougsModalClose
            (click)="planModalService.onModalClose(planModalService.selectedPlan)"
            class="cancel-plan-confirmation"
            >Annuler</a
          >
        </div>
      }

      @if (planModalService.step === 1) {
        <div>
          <i
            (click)="
              !planModalService.isConfirmingPlanSelection && planModalService.resetStep(planModalService.selectedPlan)
            "
            class="fal fa-arrow-left pointer arrow-icon mr-16 p-8"
          ></i>

          <dougs-button
            (click)="planModalService.confirmPlanSelection(planModalService.selectedPlan, planModalService.modalRef)"
            color="primary"
            [disabled]="!planModalService.accountingYearConfirmation || planModalService.isConfirmingPlanSelection"
          >
            {{ planModalService.selectedPlanSaleState === 'downsell' ? 'Valider mon pack' : 'Procéder au paiement' }}
          </dougs-button>
        </div>
      }
    </div>
  }
}
