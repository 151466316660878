<i class="fal fa-lightbulb-on color-primary-700 mb-8 light-icon"></i>
<div [ngClass]="{ 'mb-8': onMobile, 'mb-16': !onMobile }">
  Les tarifs attractifs de Dougs sont déterminés en tenant compte de l’ensemble des dépenses sur une année fiscale
  complète, puis mensualisés.
</div>
<div [ngClass]="{ 'mb-8': onMobile, 'mb-16': !onMobile }">
  Ces frais incluent la réalisation de vos obligations, par exemple : liasse fiscale, bilan, assemblée générale
  ordinaire, dépôt des comptes (frais de greffe inclus).
</div>
<div
  [ngClass]="{ 'mb-8': onMobile, 'mb-16': !onMobile }"
  *ngIf="selectedPlan?.adjustment && selectedPlan.adjustment?.amount > 0"
>
  <span>
    Le
    <b>rattrapage</b>
    de votre comptabilité du {{ selectedPlan.adjustment.startAt | date: 'dd MMMM yyyy' }} au
    {{ selectedPlan.adjustment.endAt | date: 'dd MMMM yyyy' }} de
    <b>{{ selectedPlan.adjustment.amount | number: '1.0-3' }}€&nbsp;HT</b>
  </span>
  <ng-container *ngIf="!(companyStateService.activeCompany$ | async)?.subscription?.plan">
    <span *ngIf="(companyStateService.activeCompany$ | async)?.subscription?.adjustmentDiscountPercent > 0">
      , dont
      <b>{{ (companyStateService.activeCompany$ | async)?.subscription?.adjustmentDiscountPercent }}% offerts</b>
      ,
    </span>
    <span>&nbsp;sera</span>
    <span *ngIf="(companyStateService.activeCompany$ | async)?.subscription?.adjustmentInstallmentPaymentCount === 1">
      reporté sur votre première facture.
    </span>
    <span *ngIf="(companyStateService.activeCompany$ | async)?.subscription?.adjustmentInstallmentPaymentCount > 1">
      réparti sur vos
      {{ (companyStateService.activeCompany$ | async)?.subscription?.adjustmentInstallmentPaymentCount }} premières
      factures.
    </span>
  </ng-container>
</div>
