import { DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DividerComponent, DougsDatePipe, TrackByPipe } from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';

@Component({
  selector: 'dougs-plan-confirmation-invoices',
  templateUrl: './plan-confirmation-invoices.component.html',
  styleUrls: ['./plan-confirmation-invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgClass, NgIf, DecimalPipe, TrackByPipe, DividerComponent, DougsDatePipe],
})
export class PlanConfirmationInvoicesComponent {
  @Input() selectedPlan!: SubscriptionPlan;
  @Input() onMobile = false;
}
