import { ChangeDetectorRef, Inject, Injectable, Type } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Observable } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';
import { AccountingOnboarding } from '@dougs/accounting/onboarding/dto';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService } from '@dougs/core/routing';
import { FlashMessage, FlashMessagesService, MODAL_DATA, ModalRef, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { CalendlyResponse, CrmStateService } from '@dougs/legal/shared';
import { ServiceState } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { CheckoutOptions } from '@dougs/services/dto';
import { MessageCode, PaymentMethodTypes, SubscriptionPlan } from '@dougs/subscription/dto';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { EntrySurveyModalComponent } from '@dougs/surveys/ui';
import { DemoModeService } from '@dougs/synchronized-accounts/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class PlanModalService {
  public step = 0;
  public selectedPlan?: SubscriptionPlan;
  packOptionConfigurationEcommerce = '';
  public accountingYearConfirmation = false;
  public isConfirmingPlanSelection = false;
  public disableClose = false;
  public selectedPlanSaleState?: 'upsell' | 'downsell';
  private plans!: SubscriptionPlan[];

  private accountingState!: ServiceState;

  public modalTitle =
    this.data?.upsellCampaign === 'onboardingCreaToAccounting'
      ? 'Modifier votre pack'
      : !this.data?.upsellCampaign
        ? 'Choisissez votre pack'
        : '&nbsp;';

  public hasContactButton =
    this.data?.upsellCampaign === 'onboardingCreaToAccounting' || (!this.data?.upsellCampaign && this.step === 0);

  constructor(
    @Inject(MODAL_DATA)
    public readonly data: {
      accountingState?: ServiceState;
      disableClose: boolean;
      upsellCampaign: string;
      desiredPlan?: string;
    },
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly modalService: ModalService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly metricsService: MetricsService,
    public readonly modalRef: ModalRef,
    private readonly router: Router,
    public readonly accountingOnboardingStateService: AccountingOnboardingStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly window: Window,
    public readonly crmStateService: CrmStateService,
    public readonly fieldsStateService: FieldsStateService,
    private readonly demoModeService: DemoModeService,
    private readonly routingService: RoutingService,
  ) {}

  plans$: Observable<SubscriptionPlan[] | void> = this.subscriptionStateService.plansPricing$.pipe(
    tap((plans: SubscriptionPlan[] | void) => {
      this.plans = plans || [];
    }),
  );

  plansOnActiveCompanyChange$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => {
      if (!this.data?.accountingState) {
        this.accountingState = this.companyServicesStateService.services.accounting;
      } else {
        this.accountingState = this.data.accountingState;
      }
      this.shouldDisableClose();
      this.pushMetrics();
    }),
    concatMap((company) => this.subscriptionStateService.refreshPlansPricing(company?.id, this.data?.upsellCampaign)),
  );

  async openCheckoutModal(company: Company, options: CheckoutOptions): Promise<string | null | undefined> {
    const { CheckoutModalComponent } = await import('../../checkout-modal/checkout-modal.component');
    return (
      await lastValueFrom(
        this.modalService
          .open<string>(CheckoutModalComponent, {
            data: {
              company,
              options,
            },
          })
          .afterClosed$.pipe(take(1)),
      )
    )?.data;
  }

  async canActivateOrShowModal(company: Company, activeUser: User): Promise<boolean> {
    const canActivateMessageCode: MessageCode | null = await this.subscriptionStateService.getCanActivateCode(
      company.id,
    );
    if (!canActivateMessageCode) {
      return false;
    }
    if (canActivateMessageCode.code === 'subscription.activation.canActivate') {
      return true;
    }
    if (canActivateMessageCode.code === 'subscription.activation.pendingEntrySurveyCompletion') {
      const canActivate: boolean = await this.openModalAndCanActivate(EntrySurveyModalComponent);
      if (canActivate) {
        return await this.canActivateOrShowModal(company, activeUser);
      }
      return false;
    }
    if (canActivateMessageCode.code === 'subscription.activation.missingCompanyFields') {
      void this.fieldsStateService.refreshCompanyFields(company);
      return true;
    }
    this.flashMessagesService.show(`Impossible d'activer l'abonnement : ${canActivateMessageCode.userMessage}`, {
      type: 'error',
      timeout: 2000,
    });
    return false;
  }

  private async openModalAndCanActivate(modalComponent: Type<unknown>): Promise<boolean> {
    const modalResult: OverlayCloseEvent<boolean | null | undefined> = await lastValueFrom(
      this.modalService.open<boolean>(modalComponent).afterClosed$,
    );
    const canActivate = !!modalResult.data;

    if (canActivate) {
      this.metricsService.pushMixpanelEvent('Accounting Checkout Address Clicked');
    }

    return canActivate;
  }

  async confirmPlanSelection(selectedPlan: SubscriptionPlan, modalRef: ModalRef): Promise<void> {
    const activeCompany: Company = this.companyStateService.activeCompany;
    const activeUser: User = this.userStateService.activeUser;

    this.isConfirmingPlanSelection = true;
    this.metricsService.pushGAEvent('confirmed-plan');
    this.metricsService.pushMixpanelEvent(
      'Accounting Checkout Payment Plan Clicked',
      this.mapMetricslData({ upsellCampaign: this.data?.upsellCampaign, planId: selectedPlan.id }),
    );
    const canActivate: boolean = await this.canActivateOrShowModal(activeCompany, activeUser);
    if (!canActivate) {
      return;
    }
    if (activeCompany.subscription?.plan) {
      this.flashMessagesService.show('Nous modifions votre abonnement, cela peut prendre quelques secondes&hellip;', {
        type: 'pending',
        timeout: 5000,
      });
      if (activeCompany.subscription?.plan !== selectedPlan.id) {
        await this.subscriptionStateService.changeSubscriptionPlan(selectedPlan, activeCompany);
      }
    } else {
      const skipCheckoutModal: boolean =
        activeCompany.subscription?.hasCard || activeCompany.subscription?.paymentMethod === PaymentMethodTypes.PARTNER;
      let token: string | null | undefined;
      if (!skipCheckoutModal) {
        token = await this.openCheckoutModal(activeCompany, {
          title: `Paiement`,
          buttonText: `Valider`,
          loadingText: `Souscription en cours...`,
        });
        if (token) {
          this.metricsService.pushMixpanelEvent(
            'Accounting Checkout Bank Card Clicked',
            this.mapMetricslData({ upsellCampaign: this.data?.upsellCampaign, planId: selectedPlan.id }),
          );
        }
      }

      if (skipCheckoutModal || token) {
        const flashMessage: FlashMessage = this.flashMessagesService.show(
          `Nous validons votre carte, cela peut prendre quelques secondes`,
          {
            type: 'pending',
            timeout: 0,
          },
        );
        const isActivationSuccess: boolean = await this.subscriptionStateService.activate(
          selectedPlan,
          activeCompany,
          token,
        );

        if (isActivationSuccess) {
          void this.demoModeService.refreshDemoMode();
        }

        this.metricsService.pushGAEvent('plan-chosen');
        this.flashMessagesService.close(flashMessage);
      }
    }
    this.metricsService.pushGAEvent('closed-plans');
    modalRef.close(true);

    await this.companyStateService.refreshCompany();
    await this.userStateService.refreshActiveUser(this.userStateService.activeUser);
    await this.companyServicesStateService.refreshServices(this.companyStateService.activeCompany.id);
    this.subscriptionStateService.openAcceptSubscriptionPlanPoliciesModal.next();
    this.isConfirmingPlanSelection = false;
  }

  async choosePlan(plan: SubscriptionPlan): Promise<void> {
    if (this.data?.upsellCampaign === 'onboardingCreaToAccounting') {
      this.modalRef.close({ plan });
      return;
    }
    this.metricsService.pushGAEvent('Modal CDP CTA Choose Plan click GA4');
    const canActivate: boolean = await this.canActivateOrShowModal(
      this.companyStateService.activeCompany,
      this.userStateService.activeUser,
    );
    if (canActivate) {
      this.selectedPlan = await this.checkAccountingPackEcommerceEligibility(plan);
      const activePlan: SubscriptionPlan | undefined = this.plans.find((p) => p.isActive);
      if (activePlan) {
        this.selectedPlanSaleState = this.selectedPlan.amount < activePlan.amount ? 'downsell' : 'upsell';
      }
      this.step = 1;
      this.cdr.markForCheck();
      this.metricsService.pushPageview('/me/plan-modal-step-1');
      this.metricsService.pushGAEvent('seen-adjustment-info');
      this.metricsService.pushMixpanelEvent(
        'Accounting Checkout Payment Plan Viewed',
        this.mapMetricslData({ upsellCampaign: this.data?.upsellCampaign, planId: plan.id }),
      );
    }
  }

  async checkAccountingPackEcommerceEligibility(selectedPlan: SubscriptionPlan): Promise<SubscriptionPlan> {
    const accountingOnboarding: AccountingOnboarding | null =
      await this.accountingOnboardingStateService.refreshAccountingOnboarding(
        this.companyStateService.activeCompany.id,
      );

    if (
      accountingOnboarding &&
      accountingOnboarding.data.packOptionsConfiguration?.ecommerce &&
      accountingOnboarding.data.packOptionsConfiguration?.ecommerce !== 'none'
    ) {
      this.packOptionConfigurationEcommerce = accountingOnboarding.data.packOptionsConfiguration?.ecommerce;
    }

    const goodPlanEcommerce: SubscriptionPlan | undefined = this.plans.find(
      (plan) =>
        plan.group === selectedPlan.group &&
        plan.options?.ecommerce?.value &&
        plan.options.ecommerce.value === this.packOptionConfigurationEcommerce,
    );
    if (goodPlanEcommerce) {
      return goodPlanEcommerce;
    }
    return selectedPlan;
  }

  resetStep(plan: SubscriptionPlan): void {
    this.step = 0;
    this.metricsService.pushPageview('/me/plan-modal-step-0');
    this.metricsService.pushMixpanelEvent(
      'Accounting Checkout Payment Plan Return',
      this.mapMetricslData({ upsellCampaign: this.data?.upsellCampaign, planId: plan.id }),
    );
  }

  async contactSales(e: Event, plan?: SubscriptionPlan): Promise<void> {
    e.preventDefault();

    this.metricsService.pushMixpanelEvent(
      {
        category: 'Accounting',
        label: 'Plans Modal Contact Sales CTA',
        action: 'Clicked',
      },
      this.mapMetricslData({ upsellCampaign: this.data?.upsellCampaign, planId: plan?.id }),
    );

    const activeUser: User = this.userStateService.activeUser;
    const activeCompany: Company = this.companyStateService.activeCompany;

    if (activeUser.profile) {
      let calendlyUrl: CalendlyResponse | undefined;
      const isAccountDeveloperAppointment = activeCompany.subscription && activeCompany.subscription.plan;

      if (isAccountDeveloperAppointment) {
        calendlyUrl = await this.crmStateService.getCalendlyAccountDeveloperLink(activeUser.id);
        calendlyUrl.url = calendlyUrl.url ? `${calendlyUrl.url}/rendez-vous-conseiller` : null;
      } else {
        calendlyUrl = await this.crmStateService.getCalendlySalesLink(activeUser.profile);
        calendlyUrl.url = calendlyUrl.url ? `${calendlyUrl.url}/20-minutes` : null;
      }

      if (!calendlyUrl?.url) {
        return;
      }

      const emailUri: string = activeUser.profile.email ? `&email=${encodeURIComponent(activeUser.profile.email)}` : '';
      const fullNameUri: string = activeUser.profile.fullName
        ? `&name=${encodeURIComponent(activeUser.profile.fullName)}`
        : '';

      let descriptionUri = '';
      let phoneUri = '';

      const description = "J'aimerais des informations sur les abonnements comptables";
      const phone = activeUser.profile.normalizedPhones[0] || '';
      if (isAccountDeveloperAppointment) {
        descriptionUri = `&a2=${encodeURIComponent(description)}`;
        phoneUri = phone ? `&a1=${encodeURIComponent(phone)}` : '';
      } else {
        descriptionUri = `&a1=${encodeURIComponent(description)}`;
        phoneUri = phone ? `&a2=${encodeURIComponent(phone)}` : '';
      }

      const queryString = `${emailUri}${fullNameUri}${descriptionUri}${phoneUri}`;
      this.window.open(`${calendlyUrl.url}?${queryString}`, '_blank');
    }
  }

  updateAccountingYearConfirmation(newAccountingYearConfirmation: boolean): void {
    this.accountingYearConfirmation = newAccountingYearConfirmation;
  }

  getModalSize(plans: SubscriptionPlan[], isMobile: boolean): 'small' | 'medium' | 'large' | 'xlarge' {
    if (isMobile) {
      return 'small';
    }

    if (this.data?.upsellCampaign) {
      switch (plans.length) {
        case 1:
          return 'medium';
        case 2:
          return 'large';
        case 3:
          return 'xlarge';
        default:
          return 'xlarge';
      }
    }

    switch (plans.length) {
      case 1:
        return 'medium';
      case 2:
        return 'medium';
      case 3:
        return 'large';
      default:
        return 'xlarge';
    }
  }

  private pushMetrics(): void {
    this.metricsService.pushPageview('/me/plan-modal-step-0');
    this.metricsService.pushMixpanelEvent(
      'Accounting Plans Modal Viewed',
      this.mapMetricslData({ upsellCampaign: this.data?.upsellCampaign }),
    );
    this.metricsService.pushIntercomEvent('Accounting Plans Modal Viewed');
  }

  shouldDisableClose(): void {
    if (!this.accountingState.isTrialing && !this.accountingState.isActive) {
      this.disableClose = true;
    }
  }

  onModalClose(plan?: SubscriptionPlan): void {
    if (this.step === 1) {
      this.metricsService.pushMixpanelEvent(
        'Accounting Checkout Payment Plan Dismissed',
        this.mapMetricslData({ upsellCampaign: this.data?.upsellCampaign, planId: plan?.id }),
      );
    } else {
      this.metricsService.pushGAEvent('closed-plans');
    }
  }

  onChooseLater(): void {
    this.modalRef.close({ plan: null });
  }

  private mapMetricslData({
    upsellCampaign,
    planId,
  }: {
    upsellCampaign?: string;
    planId?: string;
  }): Record<string, unknown> {
    const metricsData: Record<string, unknown> = {};

    if (upsellCampaign) {
      metricsData.upsellCampaign = upsellCampaign;
    }
    if (planId) {
      metricsData.planId = planId;
    }

    return metricsData;
  }
}
