import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { combineLatest, concatMap, map, Observable } from 'rxjs';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import {
  AvatarComponent,
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  TrackByPipe,
} from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { UserStateService } from '@dougs/user/shared';
import { PlanModalService } from '../service/plan-modal.service';
import { PlanSelectionDetailsComponent } from './plan-selection-details/plan-selection-details.component';

@Component({
  selector: 'dougs-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    NgClass,
    PlanSelectionDetailsComponent,
    AsyncPipe,
    TrackByPipe,
    AvatarComponent,
    ButtonComponent,
    PanelInfoComponent,
  ],
})
export class PlanSelectionComponent {
  hasAccrualAccounting = false;

  private _plans!: SubscriptionPlan[];

  @Input()
  set plans(plans: SubscriptionPlan[]) {
    this._plans = plans;

    this.hasAccrualAccounting = !!plans?.some((plan) => plan.hasCustomAmount);
  }

  get plans(): SubscriptionPlan[] {
    return this._plans;
  }

  @Input() disableClose = false;
  @Input() modalSize: 'small' | 'medium' | 'large' | 'xlarge' = 'small';

  @Output() choosePlan: EventEmitter<SubscriptionPlan> = new EventEmitter<SubscriptionPlan>();

  constructor(
    private readonly metricsService: MetricsService,
    public readonly userStateService: UserStateService,
    public readonly accountingOnboardingStateService: AccountingOnboardingStateService,
    public readonly planModalService: PlanModalService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  refreshAccountingOnboarding$ = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.accountingOnboardingStateService.refreshAccountingOnboarding(activeCompany.id)),
  );

  isFirmPrice$: Observable<boolean> = combineLatest([
    this.accountingOnboardingStateService.accountingOnboarding$,
    this.companyStateService.activeCompany$,
    this.userStateService.activeUser$,
  ]).pipe(
    map(([onboarding, activeCompany, activeUser]) => {
      // hack: see monolith's userSignedUpBeforeEcommerceOptionsLimit
      //  (if already removed in monolith, remove hard-coded, Date-based condition)
      if (
        new Date(activeUser.createdAt) < new Date('2023-05-24T07:00:00.000Z') ||
        activeCompany.flags.includes('noPackOptions')
      ) {
        return true;
      }
      return !!onboarding?.data?.packOptionsConfiguration?.ecommerce;
    }),
  );

  onModalClose(): void {
    this.metricsService.pushMixpanelEvent(
      'Accounting Plans Modal Dismissed',
      this.planModalService.data?.upsellCampaign
        ? {
            upsellCampaign: this.planModalService.data.upsellCampaign,
          }
        : undefined,
    );
    this.metricsService.pushGAEvent('closed-plans');
  }
}
