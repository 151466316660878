<div dougsModalTitle>
  <h6>Votre échéancier</h6>
  <i dougsModalClose class="fal fa-times" (click)="planModalService.onModalClose(selectedPlan)"></i>
</div>
<div dougsModalContent="large" class="modal-content">
  <div [ngClass]="{ flex: !onMobile }" class="resume-container">
    <div class="invoices-container">
      <div class="title mb-24">
        <h3>Votre abonnement est de {{ planModalService.selectedPlan?.amount }}€ HT/mois</h3>
        <span>Détail de vos 2 prochaines factures :</span>
      </div>

      <dougs-plan-confirmation-invoices
        [selectedPlan]="selectedPlan"
        [onMobile]="onMobile"
      ></dougs-plan-confirmation-invoices>
    </div>

    <div class="recap-container mb-16" [ngClass]="{ 'p-16 mt-16': onMobile, 'py-16 px-24 mr-40': !onMobile }">
      <dougs-plan-confirmation-adjustment
        [selectedPlan]="selectedPlan"
        [onMobile]="onMobile"
      ></dougs-plan-confirmation-adjustment>
      <dougs-plan-confirmation-remainder [selectedPlan]="selectedPlan"></dougs-plan-confirmation-remainder>
      <dougs-plan-confirmation-discounts
        [selectedPlan]="selectedPlan"
        [onMobile]="onMobile"
      ></dougs-plan-confirmation-discounts>
      <div class="mt-16">
        <a (click)="planModalService.contactSales($event, selectedPlan)" class="color-primary" href="#">
          Contacter un conseiller
        </a>
      </div>
    </div>
  </div>

  <dougs-plan-confirmation-date
    [selectedPlan]="selectedPlan"
    [accountingYearConfirmation]="accountingYearConfirmation"
    [isConfirmingPlanSelection]="isConfirmingPlanSelection"
    (updateAccountingYearConfirmation)="updateAccountingYearConfirmation.emit($event)"
    class="flex-date-container"
  ></dougs-plan-confirmation-date>
</div>
