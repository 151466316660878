import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalCloseDirective, ModalContentDirective, ModalRef, ModalTitleDirective } from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { PlanModalService } from '../service/plan-modal.service';
import { PlanConfirmationAdjustmentComponent } from './plan-confirmation-adjustment/plan-confirmation-adjustment.component';
import { PlanConfirmationDateComponent } from './plan-confirmation-date/plan-confirmation-date.component';
import { PlanConfirmationDiscountsComponent } from './plan-confirmation-discounts/plan-confirmation-discounts.component';
import { PlanConfirmationInvoicesComponent } from './plan-confirmation-invoices/plan-confirmation-invoices.component';
import { PlanConfirmationRemainderComponent } from './plan-confirmation-remainder/plan-confirmation-remainder.component';

@Component({
  selector: 'dougs-plan-confirmation',
  templateUrl: './plan-confirmation.component.html',
  styleUrls: ['./plan-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    NgClass,
    PlanConfirmationAdjustmentComponent,
    PlanConfirmationRemainderComponent,
    PlanConfirmationDiscountsComponent,
    PlanConfirmationInvoicesComponent,
    PlanConfirmationDateComponent,
  ],
})
export class PlanConfirmationComponent {
  @Input() selectedPlan!: SubscriptionPlan;
  @Input() modalRef!: ModalRef;
  @Input() accountingYearConfirmation!: boolean;
  @Input() isConfirmingPlanSelection!: boolean;
  @Input() onMobile = false;

  @Output() updateAccountingYearConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public readonly planModalService: PlanModalService) {}
}
