import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { ResponsiveService } from '@dougs/core/responsive';
import { ButtonComponent, ModalCloseDirective, ModalFooterDirective } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { PlanConfirmationComponent } from './plan-confirmation/plan-confirmation.component';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { PlanModalService } from './service/plan-modal.service';

@Component({
  selector: 'dougs-plans-modal',
  templateUrl: './plans-modal.component.html',
  styleUrls: ['./plans-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PlanModalService],
  standalone: true,
  imports: [
    NgIf,
    PlanSelectionComponent,
    PlanConfirmationComponent,
    ModalFooterDirective,
    ButtonComponent,
    ModalCloseDirective,
    AsyncPipe,
  ],
})
export class PlansModalComponent {
  constructor(
    public readonly planModalService: PlanModalService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly responsiveService: ResponsiveService,
  ) {}
}
