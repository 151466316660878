<div class="date__container mt-24">
  <p class="small mb-8">
    Ces factures sont relatives à l’exercice du
    <b>{{
      (selectedPlan.adjustment?.startAt
        ? selectedPlan.adjustment.startAt
        : (companyStateService.activeCompany$ | async)?.accountingYear.openingDate
      ) | date: 'dd MMMM yyyy'
    }}</b>
    au
    <b>{{ (companyStateService.activeCompany$ | async)?.accountingYear.closingDate | date: 'dd MMMM yyyy' }}.</b>
  </p>

  <div class="date__confirmation p-8">
    <dougs-checkbox
      class="mr-8"
      (ngModelChange)="updateAccountingYearConfirmation.emit($event)"
      [disabled]="isConfirmingPlanSelection"
      [ngModel]="accountingYearConfirmation"
    ></dougs-checkbox>
    <p
      class="small pointer"
      (click)="updateAccountingYearConfirmation.emit(!accountingYearConfirmation)"
      [ngClass]="{
        'color-primary': accountingYearConfirmation,
        'color-primary-700': !accountingYearConfirmation
      }"
    >
      Confirmer que les dates de début et fin d’exercice sont correctes
    </p>
  </div>
</div>
