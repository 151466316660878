@if (refreshAccountingOnboarding$ | async) {}
<div dougsModalTitle>
  <h6 [innerHtml]="planModalService.modalTitle"></h6>
  @if (
    userStateService.loggedInUser.isAccountantOrAdmin ||
    !disableClose ||
    planModalService.data?.upsellCampaign === 'onboardingCreaToAccounting'
  ) {
    <i dougsModalClose class="fal fa-times" (click)="onModalClose()"></i>
  }
</div>
@switch (planModalService.data?.upsellCampaign) {
  @case ('VATRemoteDeclaration') {
    <div [dougsModalContent]="modalSize" class="custom-modal-content">
      @if (plans) {
        <div class="plans-container">
          <div class="vat-upsell-content">
            <div class="vat-upsell-avatar">
              <dougs-avatar size="xlarge" image="images/avatar/img-patrick.png"></dougs-avatar>
            </div>
            <div class="vat-upsell-wording">
              <h3>Gagnez du temps, <span class="color-primary">déclarez en 1 clic</span></h3>
              <ul class="vat-upsell-list">
                <li>Plus besoin de vous connecter sur le site des impôts</li>
                <li>Oubliez les copier/coller</li>
                <li>Évitez les erreurs et pénalités</li>
              </ul>
            </div>
            @if (planModalService.step === 0) {
              <dougs-button color="secondary" (click)="planModalService.contactSales($event)">
                Contacter un conseiller
              </dougs-button>
            }
          </div>
          @for (plan of plans; track plan.id) {
            <div class="plan">
              <dougs-plan-selection-details
                class="plan-details"
                [plan]="plan"
                [isFirmPrice]="isFirmPrice$ | async"
                (choosePlan)="choosePlan.emit($event)"
              ></dougs-plan-selection-details>
            </div>
          }
        </div>
      }
      @if (planModalService.disableClose) {
        <div class="mt-32 py-8 center">
          <a href="#" (click)="planModalService.rejectPlanSelection($event)" class="reject-plan-selection">
            Ne pas souscrire
          </a>
        </div>
      }
    </div>
  }
  @case ('onboardingCreaToAccounting') {
    <div [dougsModalContent]="modalSize">
      @if (plans) {
        <div class="plans-container">
          @for (plan of plans; track plan.id; let first = $first; let last = $last) {
            <div class="plan">
              <dougs-plan-selection-details
                class="plan-details"
                [plan]="plan"
                [isFirmPrice]="isFirmPrice$ | async"
                (choosePlan)="choosePlan.emit($event)"
              ></dougs-plan-selection-details>
            </div>
          }
        </div>
      }
    </div>
    <div class="custom-modal-content__choose-later mt-16">
      <a class="pointer" (click)="planModalService.onChooseLater()">Choisir plus tard</a>
    </div>
  }
  @default {
    @if (!planModalService.data || (planModalService.data && !planModalService.data.upsellCampaign)) {
      <div [dougsModalContent]="modalSize" class="custom-modal-content">
        @if (
          (companyStateService.activeCompany$ | async).flags.includes('enableAccrualAccountingPlans') &&
          hasAccrualAccounting
        ) {
          <dougs-panel-info type="default-light">
            Vous méritez une expérience sur mesure. C'est pourquoi nous vous offrons un rendez-vous personnalisé avec
            l'un de nos conseillers pour élaborer un devis adapté à vos besoins spécifiques.
          </dougs-panel-info>
        }
        @if (plans) {
          <div class="plans-container">
            @for (plan of plans; track plan.id; let first = $first; let last = $last) {
              <div class="plan">
                <dougs-plan-selection-details
                  class="plan-details"
                  [plan]="plan"
                  [isFirmPrice]="isFirmPrice$ | async"
                  (choosePlan)="choosePlan.emit($event)"
                ></dougs-plan-selection-details>
              </div>
            }
          </div>
        }
      </div>
    }
  }
}
