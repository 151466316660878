import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { CheckboxComponent, DougsDatePipe } from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';

@Component({
  selector: 'dougs-plan-confirmation-date',
  templateUrl: './plan-confirmation-date.component.html',
  styleUrls: ['./plan-confirmation-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CheckboxComponent, FormsModule, NgClass, AsyncPipe, DougsDatePipe],
})
export class PlanConfirmationDateComponent {
  @Input() selectedPlan!: SubscriptionPlan;
  @Input() company!: Company;
  @Input() accountingYearConfirmation!: boolean;
  @Input() isConfirmingPlanSelection!: boolean;

  @Output() updateAccountingYearConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public readonly companyStateService: CompanyStateService) {}
}
