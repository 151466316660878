import { DecimalPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DividerComponent } from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';

@Component({
  selector: 'dougs-plan-confirmation-remainder',
  templateUrl: './plan-confirmation-remainder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, DividerComponent, DecimalPipe],
})
export class PlanConfirmationRemainderComponent {
  @Input() selectedPlan!: SubscriptionPlan;
}
