<div
  *ngFor="
    let invoice of selectedPlan.upcomingInvoices;
    let lastInvoice = last;
    let firstInvoice = first;
    trackBy: 'id' | trackBy
  "
  class="billing-invoice-container"
  [ngClass]="{
    'no-padding-top': firstInvoice,
    'futur-invoices': lastInvoice,
    'pt-0 px-16 pb-16': onMobile,
    'pl-16 pb-16': !onMobile
  }"
>
  <h6 class="line-title mb-8">Facture du {{ invoice?.date | date: 'dd MMMM yyyy' }}</h6>
  <div *ngFor="let line of invoice.items; trackBy: 'id' | trackBy" class="invoice-line mb-4">
    <div class="line-description" [ngClass]="{ 'color-primary': line.totalAmount < 0 }">
      {{ line.fullDescription }}
    </div>
    <dougs-divider></dougs-divider>
    <div class="line-total" [ngClass]="{ 'color-primary': line.totalAmount < 0 }">
      {{ line.totalAmount | number: '1.2-2' }}€
    </div>
  </div>
  <div class="invoice-total py-4 mt-4">
    <div>
      <b>Total HT {{ invoice.amountExcludingTaxes | number: '1.2-2' }}€</b>
    </div>
    <div>Total TTC {{ invoice.amount | number: '1.2-2' }}€</div>
  </div>
</div>
