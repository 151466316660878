<div class="mb-16" *ngIf="selectedPlan?.remainder && selectedPlan.remainder?.amount">
  <span>
    Un
    <b>reliquat</b>
    de
    <b>{{ selectedPlan.remainder.amount | number: '1.2-2' }} €&nbsp;HT</b>
    correspond au montant minimum pour réaliser votre bilan depuis le début d'année en cours de votre exercice
    comptable.
  </span>
</div>
