<div class="mb-16" *ngIf="(companyStateService.activeCompany$ | async)?.subscription?.paidToThirdPartyMonthCount > 0">
  <span>
    Vous avez déjà payé
    {{ (companyStateService.activeCompany$ | async)?.subscription?.paidToThirdPartyMonthCount }} mois d'abonnement à
    notre partenaire {{ (userStateService.activeUser$ | async).referrer?.profile?.fullName }}. Ce montant sera déduit de
    vos prochaines factures.
  </span>
</div>
<div
  *ngIf="
    (companyStateService.activeCompany$ | async)?.subscription?.freeMonthCount > 0 &&
    !(companyStateService.activeCompany$ | async)?.subscription?.plan
  "
>
  Nous vous
  <b>offrons {{ (companyStateService.activeCompany$ | async)?.subscription?.freeMonthCount }} mois d'abonnement</b>
  à
  <b
    >{{
      (companyStateService.activeCompany$ | async)?.subscription?.freeMonthCount * selectedPlan?.amount
        | number: '1.0-3'
    }}€&nbsp;HT</b
  >
  sur votre facture.
</div>
<div class="mt-16" *ngIf="selectedPlan?.isReferred">
  Votre parrain vous
  <b>offre 1 mois d’abonnement</b>
  !
</div>
