import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent, DougsDatePipe, PillComponent, TooltipDirective, TrackByPipe } from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { UserStateService } from '@dougs/user/shared';
import { PlanModalService } from '../../service/plan-modal.service';
import { PlanSelectionDetailsComputePlanNamePipe } from './plan-selection-details-compute-plan-name.pipe';
import { PlanSelectionDetailsComponentService } from './plan-selection-details.service';

@Component({
  selector: 'dougs-plan-selection-details',
  templateUrl: './plan-selection-details.component.html',
  styleUrls: ['./plan-selection-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ButtonComponent,
    NgFor,
    AsyncPipe,
    DecimalPipe,
    TrackByPipe,
    TooltipDirective,
    PillComponent,
    DougsDatePipe,
    PlanSelectionDetailsComputePlanNamePipe,
  ],
  providers: [PlanSelectionDetailsComponentService],
})
export class PlanSelectionDetailsComponent {
  @Input()
  set plan(plan: SubscriptionPlan) {
    this.planSelectionDetailsComponentService.setPlan(plan);
  }

  @Input() isFirmPrice = false;

  @Output() choosePlan: EventEmitter<SubscriptionPlan> = new EventEmitter<SubscriptionPlan>();

  constructor(
    public readonly companyStateService: CompanyStateService,
    public readonly userStateService: UserStateService,
    public readonly planModalService: PlanModalService,
    public readonly planSelectionDetailsComponentService: PlanSelectionDetailsComponentService,
  ) {}
}
