import { Pipe, PipeTransform } from '@angular/core';
import { LMNP_PLAN_GROUP, PLAN_MULTI_EXCLU_PREFIX, SCI_PLAN_GROUP, SubscriptionPlan } from '@dougs/subscription/dto';

@Pipe({
  standalone: true,
  name: 'planSelectionDetailsComputePlanName',
})
export class PlanSelectionDetailsComputePlanNamePipe implements PipeTransform {
  transform(plan: SubscriptionPlan): string {
    if (plan.id.startsWith(PLAN_MULTI_EXCLU_PREFIX)) {
      return 'Multi-Société';
    } else if ([SCI_PLAN_GROUP, LMNP_PLAN_GROUP].includes(plan.group)) {
      return plan.name;
    }

    return plan.group;
  }
}
