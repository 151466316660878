import { AsyncPipe, DecimalPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { DividerComponent } from '@dougs/ds';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-plan-confirmation-discounts',
  templateUrl: './plan-confirmation-discounts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, DividerComponent, NgClass, DecimalPipe, AsyncPipe],
})
export class PlanConfirmationDiscountsComponent {
  @Input() selectedPlan!: SubscriptionPlan;
  @Input() onMobile = false;

  constructor(
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
  ) {}
}
