@if ((companyStateService.activeCompany$ | async).subscription; as subscription) {
  <div class="plan-header pt-24 pl-24 pr-24 pb-8">
    @if (planSelectionDetailsComponentService.plan.ribbon) {
      <div class="plan-ribbon py-4 px-8">✦ {{ planSelectionDetailsComponentService.plan.ribbon }} ✦</div>
    }
    <div class="plan-title">
      Pack
      <h4 class="plan-title-plan">
        {{ planSelectionDetailsComponentService.plan | planSelectionDetailsComputePlanName }}
      </h4>
    </div>

    <div class="plan-price">
      @if (planSelectionDetailsComponentService.plan.hasCustomAmount && subscription.customAmount === null) {
        <span class="additional-infos">dès&nbsp;</span>
      }
      <span class="price mr-4"
        >{{
          planSelectionDetailsComponentService.plan.amount -
            planSelectionDetailsComponentService.plan?.options?.ecommerce?.amount | number: '1.0-3'
        }}€</span
      >
      <span class="additional-infos">HT/mois</span>
    </div>

    @if (
      planSelectionDetailsComponentService.plan?.options?.ecommerce?.value &&
      planSelectionDetailsComponentService.plan?.options?.ecommerce?.value !== 'none'
    ) {
      <div class="plan-extension">
        <span>
          + Extension E-commerce à
          {{ planSelectionDetailsComponentService.plan?.options?.ecommerce?.amount }}€&nbsp;HT/mois
        </span>
      </div>
    }
  </div>

  <div class="plan-choice separation py-16 px-24">
    @if (
      !planModalService.data?.upsellCampaign &&
      planSelectionDetailsComponentService.hasSpecialFees &&
      planSelectionDetailsComponentService.displayedSpecialFees > 0 &&
      (!planSelectionDetailsComponentService.plan.hasCustomAmount ||
        (planSelectionDetailsComponentService.plan.hasCustomAmount && subscription.customAmount !== null))
    ) {
      <div class="fees-container mb-4">
        <div class="fees-text">
          Frais de début d'abonnement (offres commerciales incluses)
          <br />
          <span class="fees-amount"
            >{{ planSelectionDetailsComponentService.displayedSpecialFees | number: '1.2-2' }}€&nbsp;HT</span
          >
          @if (planSelectionDetailsComponentService.plan.adjustment?.discountPercent > 0) {
            (dont {{ planSelectionDetailsComponentService.plan.adjustment.discountPercent }}% offerts sur le rattrapage)
          }
          @if (planSelectionDetailsComponentService.plan.adjustment?.installmentPaymentCount > 1) {
            étalés sur {{ planSelectionDetailsComponentService.plan.adjustment.installmentPaymentCount }} mensualités
          }
        </div>

        <ng-template #specialFeesTooltip>
          <div class="fees-tooltip-content">
            @if (planSelectionDetailsComponentService.plan.adjustment?.amount) {
              <div>
                Des frais de rattrapage de
                {{ planSelectionDetailsComponentService.plan.adjustment.amount | number: '1.2-2' }}€&nbsp;HT
                correspondent à la période du
                {{ planSelectionDetailsComponentService.plan.adjustment.startAt | date: 'dd MMMM yyyy' }}
                au {{ planSelectionDetailsComponentService.plan.adjustment.endAt | date: 'dd MMMM yyyy' }}.
              </div>
            }
            @if (planSelectionDetailsComponentService.plan.remainder?.amount) {
              <div>
                Un reliquat de
                {{ planSelectionDetailsComponentService.plan.remainder.amount | number: '1.2-2' }}€&nbsp;HT correspond
                au montant minimum pour réaliser votre bilan.
              </div>
            }
            @if (planSelectionDetailsComponentService.discountAmount) {
              <div>
                Les offres commerciales d'un montant de
                {{ planSelectionDetailsComponentService.discountAmount | number: '1.2-2' }}€&nbsp;HT sont
                automatiquement déduites du montant des frais affiché.
              </div>
            }
            <div>Retrouvez toutes vos offres en détail sur notre devis&nbsp;!</div>
          </div>
        </ng-template>
        <i [dougsTooltip]="specialFeesTooltip" class="fal fa-info-circle"></i>
      </div>
    }

    @if (subscription?.paidToThirdPartyMonthCount > 0) {
      <div class="third-party-container mb-4">
        {{ subscription.paidToThirdPartyMonthCount }} mois déjà payé{{
          subscription.paidToThirdPartyMonthCount > 1 ? 's' : ''
        }}
        à notre partenaire {{ (userStateService.activeUser$ | async).referrer?.profile?.fullName }}
        {{
          subscription.paidToThirdPartyMonthCount * planSelectionDetailsComponentService.plan.amount | number: '1.2-2'
        }}€&nbsp;HT
      </div>
    }

    <div class="button-container mt-8 mb-4">
      @if (planSelectionDetailsComponentService.plan.id == planModalService.data?.desiredPlan?.id) {
        <dougs-button color="secondary" [disabled]="true" [fullWidth]="true">
          <i class="fal fa-check"></i>
          Pack sélectionné
        </dougs-button>
      } @else if (planSelectionDetailsComponentService.plan.isActive) {
        <dougs-button color="secondary" [disabled]="true" [fullWidth]="true">
          <i class="fal fa-check"></i>
          Pack choisi
        </dougs-button>
      } @else if (
        !planSelectionDetailsComponentService.plan.isActive &&
        planSelectionDetailsComponentService.plan.hasCustomAmount &&
        subscription.customAmount === null
      ) {
        <dougs-button
          [fullWidth]="true"
          (click)="planModalService.contactSales($event, planSelectionDetailsComponentService.plan)"
        >
          Prendre RDV avec un conseiller
        </dougs-button>
      } @else if (
        !planSelectionDetailsComponentService.plan.isActive &&
        (!planSelectionDetailsComponentService.plan.hasCustomAmount ||
          (planSelectionDetailsComponentService.plan.hasCustomAmount && subscription.customAmount !== null))
      ) {
        <dougs-button
          [disabled]="!planSelectionDetailsComponentService.plan.isAvailable"
          [fullWidth]="true"
          (click)="choosePlan.emit(planSelectionDetailsComponentService.plan)"
        >
          {{ planSelectionDetailsComponentService.ctQuoteLabel }}
          @if (
            !planModalService.data?.upsellCampaign ||
            planModalService.data?.upsellCampaign !== 'onboardingCreaToAccounting'
          ) {
            <i class="fa-arrow-right fal ml-8"></i>
          }
        </dougs-button>
      }
    </div>
    <div class="without-subscription">Sans engagement</div>
  </div>

  <div class="plan-details py-16 px-24">
    <div class="items-container">
      @for (item of planSelectionDetailsComponentService.plan.items; track item.id; let index = $index) {
        <div class="tip separation pb-8 mb-8">
          @if (item.upcoming) {
            <span class="item-upcoming">À VENIR<br /></span>
          }
          @if (!planModalService.data?.upsellCampaign && item.pills) {
            <div class="pb-4">
              @for (pill of item.pills; track pill.label) {
                <dougs-pill [type]="pill.type" class="bold">{{ pill.label }}</dougs-pill>
              }
            </div>
          }
          <div class="item-content">
            <span [ngClass]="{ 'first-item': planModalService.data?.upsellCampaign && i === 0 }">{{
              item.content
            }}</span>
            @if (item.tooltip) {
              <i [dougsTooltip]="item.tooltip" class="ml-8 fal fa-info-circle item-tooltip"></i>
            }
          </div>
        </div>
      }
    </div>
  </div>
}
