import { Injectable } from '@angular/core';
import { SubscriptionPlan } from '@dougs/subscription/dto';
import { PlanModalService } from '../../service/plan-modal.service';

@Injectable()
export class PlanSelectionDetailsComponentService {
  constructor(private readonly planModalService: PlanModalService) {}
  private _plan!: SubscriptionPlan;

  setPlan(plan: SubscriptionPlan): void {
    this._plan = plan;

    this.computeDiscountAmount();
    this.computeDisplayedSpecialFees();
    this.computeHasSpecialFees();
    this.computeQuoteCtaLabel();
  }

  get plan(): SubscriptionPlan {
    return this._plan;
  }

  private _discountAmount = 0;

  get discountAmount(): number {
    return this._discountAmount;
  }

  private _hasSpecialFees = false;

  get hasSpecialFees(): boolean {
    return this._hasSpecialFees;
  }

  private _displayedSpecialFees = 0;

  get displayedSpecialFees(): number {
    return this._displayedSpecialFees;
  }

  private _ctaQuoteLabel = 'Voir le devis';

  get ctQuoteLabel(): string {
    return this._ctaQuoteLabel;
  }

  private computeDiscountAmount(): void {
    this._discountAmount = 0;
    for (const upcomingInvoices of this._plan.upcomingInvoices) {
      for (const item of upcomingInvoices.items) {
        if (item.totalAmount < 0) {
          this._discountAmount += item.totalAmount;
        }
      }
    }
  }

  private computeDisplayedSpecialFees(): void {
    this._displayedSpecialFees =
      (this._plan.adjustment?.amount || 0) + (this._plan.remainder?.amount || 0) + this._discountAmount;
  }

  private computeHasSpecialFees(): void {
    this._hasSpecialFees = !!(
      this._displayedSpecialFees ||
      this._plan.adjustment?.amount ||
      this._plan.remainder?.amount ||
      this._discountAmount
    );
  }

  private computeQuoteCtaLabel(): void {
    if (this.planModalService.data?.upsellCampaign) {
      switch (this.planModalService.data?.upsellCampaign) {
        case 'onboardingCreaToAccounting':
          this._ctaQuoteLabel = 'Choisir ce pack';
          return;
        default:
          this._ctaQuoteLabel = 'Choisir';
          return;
      }
    }
    if (!this._plan.isAvailable) {
      this._ctaQuoteLabel = 'Non disponible*';
      return;
    }

    this._ctaQuoteLabel = 'Voir le devis';
  }
}
